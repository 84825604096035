.fade-enter {
  opacity: 0;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition:
    background-color 0.6s ease,
    transform 0.6s ease,
    opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition:
    background-color 0.6s ease,
    opacity 300ms ease-out,
    transform 0.6s ease;
}
